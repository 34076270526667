import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// React boostrap
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
//SEO Matter
import Seo from "../components/seo"
const IndexPage = () => (
  <div id="Index">
    <Seo
      title="Bike Racks | Bike Stands | Bike Shelters - BIKESAFE"
      description="Bike Safe are bike parking specialists - we manufacture bike shelters, bike stands and bike racks to the highest quality in our factory. Official providers of bike parking to many famous brands."
    />
    <Layout>
      <Container fluid className="p-0">
        <Row className="mb-5">
          <Col className="text-center no-padding-or-margin">
            <div className="parallax-home"><div className="home-title"><h1 className="home-title-text">
              The Bike Parking</h1>
              <h1 className="home-title-text-under opacity">
                Specialists</h1></div></div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Bike Racks</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Two Tier Bike Rack</h3>
              <p>Space saving, superb usability and excellent value! The Two Tier Rack is a very innovative and user friendly cycle parking system which allows you to house twice as many bikes in the same area as any standard cycle rack.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Two Tier Bike Rack</h3>
              <p>The BikeSafe Two Tier Bike Rack is a pioneering and user friendly cycle parking system, with excellent space saving bike storage, superb usability and high value...</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Free Standing Wall Dock</h3>
              <p>A variation on our popular Wall Dock, the Free Standing Wall Dock lets users make the most from floor space. The frame can be securely bolted to the floor...</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">The Semi-Vertical Bike Rack</h3>
              <p>This bike rack can help contribute towards gaining ENE8 Cycle storage credits when used in the appropriate situation, under the Code for Sustainable Homes.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Bike Stands</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Sheffield Bike Stand</h3>
              <p>The classic and popular design is perfect for those seeking great value, low maintenance and minimalist bike parking.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Black Sheffield Bike Stand</h3>
              <p>The shape allows the bike frame & wheel to be securely locked on to the stand. The classic and popular design is perfect for those seeking great value.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
      <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Bike Shelters</h1>
          </Col>
        </Row>
                <Row>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier New York</h3>
                            <p>This stunning bike shelter comes with an anti-climb roof perfect for installations into public space. Supplied with two tier racks this cycle shelter is the best option on the market...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier Ark Shelter</h3>
                            <p>Designed to double the number of cycles you can fit in a relatively small space, with 2 rows of bike parking. This Cycle Shelter is available in a galvanised or powder coated RAL code colour...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier Bristol Shelter</h3>
                            <p>This Bike Shelter comes with the best all-round features to suit any environment. Shelter available in a galvanised or polyester powder coated RAL code colour finish...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">The Bike Parking Specialists</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="p-2">
            <p>Bike Safe are official suppliers of all Bike Stands, Bike Racks and Bike Shelters to Transport for London Barclays Cycle Superhighway Scheme covering all four of the existing routes.</p>
            <p>Barclays Cycle Superhighways are new cycle routes that run into central London from outer London to provide cyclists with safer, faster and more direct journeys into the city.</p>
            <p>Barclays Cycle Superhighways will provide thousands of new cycle parking spaces, free or subsidised Commuter Cycle Training, as well as better facilities for cyclists at work through Bike Safe - the UK's Bike Parking Specialist.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
)

export default IndexPage
